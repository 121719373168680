import { useLoaderData } from 'react-router-dom';

type LoaderData = { 
  flowId: string;
}

export async function loader({ params }) {
  return { flowId: params.flowId };
}

const FlowDetailsPage = () => {
  const { flowId } = useLoaderData() as LoaderData;
  return (
    <div className='flex flex-1'>Flow Details Page, {flowId}</div>
  );
}

export default FlowDetailsPage;