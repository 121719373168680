import { createContext, useEffect, useState } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import awsExports from '../aws-exports';
import { getAWSConfig } from '../utils/configUtils';

export const UserContext = createContext(null);

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    console.log('CONFIGURING AWS');
    Amplify.configure(getAWSConfig());

    if (process.env.NODE_ENV !== 'development') {
      Amplify.configure({
        API: {
          graphql_endpoint: process.env.GRAPHQL_ENDPOINT,
          graphql_headers: async () => ({
            'x-api-key': process.env.GRAPHQL_API_KEY,
          })
        }
      });
    }

    const getLoggedInUser = async () => {
      try {
        const loggedInUser = await Auth.currentAuthenticatedUser();
        if (loggedInUser) {
          setUser(loggedInUser);
        }
      } catch (error) {
        console.log('User is not logged in');
      }
    };
    getLoggedInUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
