import { useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Auth } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from './providers/UserProvider';

declare global {
  interface Window {
    google: any;
  }
}

const App = () => {
  //console.log('CONFIGURING AWS!!');
  //Amplify.configure(awsExports);

  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  let menuItemText = 'Sign In';
  let menuItemCb = async () => navigate('/login');
  if (user) {
    menuItemText = 'Sign Out';
    menuItemCb = async () => {
      await Auth.signOut();
      setUser(null);
      navigate('/login');
    };
  }

  const popupTrigger = (
    <FontAwesomeIcon
      className="cursor-pointer"
      icon={faUserCircle}
      color="black"
      size="xl"
    />
  );

  return (
    <div className="flex flex-col h-full items-center bg-white">
      <div className="flex flex-col max-w-lg h-full w-full border-l border-r border-gray-200">
        <div className="flex bg-white h-14 w-full items-center justify-between px-10 border-b border-gray-200">
          <span
            className="font-['Apple_Chancery'] text-3xl"
            onClick={() => navigate('/')}
          >
            Modelit
          </span>
          <Popup trigger={popupTrigger} closeOnDocumentClick>
            <ul className="text-sm border rounded border-gray-200 bg-white">
              <li
                className="p-2 cursor-pointer hover:bg-gray-100"
                onClick={menuItemCb}
              >
                {menuItemText}
              </li>
            </ul>
          </Popup>
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default App;
