// @ts-nocheck
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Flow, ParameterType } from '../../API';
import { API } from 'aws-amplify';
import { ConsoleContext } from '../../providers/ConsoleProvider';
import * as mutations from '../../graphql/mutations';

interface FlowFormProps {
  flow: Flow
}

const FlowForm = ({ flow }: FlowFormProps) => {
  const { inputSpec } = flow;
  const defaultValues = inputSpec.reduce((accum, currSpec) => {
    accum[currSpec.key] = currSpec.defaultValue;
    return accum;
  }, {});

  const { messages, setMessages } = useContext(ConsoleContext);
  const { register, handleSubmit, formState: { errors } } = useForm({ defaultValues });

  const onSubmit = async (data) => {
    const messagesWithInput = [
      ...messages,
      {
        name: flow.name,
        sender: 'user',
        data,
      }
    ];
    setMessages(messagesWithInput);

    var predictionResponse = await API.graphql({
      query: mutations.genPrediction,
      variables: {
          flow: flow.alias, 
          params: encodeURIComponent(JSON.stringify(data))
      },
      authMode: 'API_KEY'
    });

    if (predictionResponse?.data?.genPrediction) {
      const genPredictionJSON = JSON.parse(predictionResponse.data.genPrediction);
      const bodyJSON = genPredictionJSON && JSON.parse(genPredictionJSON.body);
      if (bodyJSON && bodyJSON.predictions) {
        const predictionResponses = bodyJSON.predictions.map(prediction => {
          return {
            name: `Response to ${flow.name}`,
            sender: 'model',
            data: prediction.prediction,
          }
        });
        const messagesWithInputAndOutput = [...messagesWithInput, ...predictionResponses]
        setMessages(messagesWithInputAndOutput);
      }
    }
  };

  const errorMessage = (key) => {
    if (!errors[key]) return;
    return <div className='text-right text-red-500 italic'>{errors[key].message}</div>
  }

  return (
    <form 
      onSubmit={handleSubmit(onSubmit)}
      className='flex-1 space-y-6'
    >
      {inputSpec.map((paramSpec) => {
        const { key, label, description, type, isArray, isRequired, dropdownOptions } = paramSpec;
        
        switch(type) {
          case ParameterType.BOOLEAN:
            return (
              <div key={key} className='flex flex-1 flex-col'>
                <label htmlFor={key} className='font-bold'>{label}</label>
                <span className='italic'>{description}</span>
                <select 
                  className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5'
                  {...register(key, { required: isRequired })}
                >
                  <option value='true'>true</option>
                  <option value='false'>false</option>
                </select>
                {errorMessage(key)}
              </div>
            );
          case ParameterType.STRING:
          case ParameterType.TEXT:
            return (
              <div key={key} className='flex flex-1 flex-col'>
                <label htmlFor={key} className='font-bold'>{label}</label>
                <span className='italic'>{description}</span>
                <textarea
                  rows={4} 
                  className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5'
                  {...register(key, { required: isRequired })}
                />
                {errorMessage(key)}
              </div>
            );
        }
      })}
      <button type='submit' className='bg-blue-500 w-full hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg'>Model It!</button>
    </form>
  );
};

export default FlowForm;