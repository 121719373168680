var globalThis = globalThis || {};

export const isGASServerEnvironment = () => {
  return (
    globalThis.hasOwnProperty('UrlFetchApp') &&
    typeof globalThis.UrlFetchApp !== 'undefined' &&
    typeof globalThis.UrlFetchApp.fetch === 'function'
  );
};

export const isGASWebEnvironment = () => {
  console.log(globalThis);
  console.log(globalThis.google);

  return (
    globalThis.hasOwnProperty('google') && globalThis.google !== 'undefined'
  );
};

// @ts-nocheck
export const isGASEnvironment = () => {
  var isGASServer = isGASServerEnvironment();
  var isGASWeb = isGASWebEnvironment();
  var isGAS = isGASServerEnvironment() || isGASWebEnvironment();
  console.log(
    `IS GAS: ${isGAS}, is Server: ${isGASServer}, is Web: ${isGASWeb}`
  );
  return isGAS;
};

const GASSTORAGE_KEY_PREFIX = '@GasStorage:';
let dataMemory = {};
let _gasStorageSyncPromise = null;
console.log('HIT THIS LINE');

/** @class */
export class GasStorage {
  //static syncPromise = null;
  /**
   * This is used to set a specific item in storage
   */
  static setItem(key, value) {
    console.log(`Set Key: ${key}, Item:${value}`);
    globalThis.setUserProperty(GASSTORAGE_KEY_PREFIX + key, value);
    dataMemory[key] = value;
    return dataMemory[key];
  }

  /**
   * This is used to get a specific key from storage
   */
  static getItem(key) {
    console.log(`Get Item for Key: ${key}`);
    return Object.prototype.hasOwnProperty.call(dataMemory, key)
      ? dataMemory[key]
      : undefined;
  }

  /**
   * This is used to remove an item from storage
   */
  static removeItem(key) {
    console.log(`Remove Key: ${key}`);
    globalThis.deleteUserProperty(GASSTORAGE_KEY_PREFIX + key);
    return delete dataMemory[key];
  }

  /**
   * This is used to clear the storage
   */
  static clear() {
    dataMemory = {};
    return dataMemory;
  }

  /**
   * Will sync the GASStorage data from PropertiesService.getUserProperties() to storageWindow GasStorage
   */
  static sync() {
    console.log('HIT HEREEE!');
    if (!_gasStorageSyncPromise) {
      console.log('NOT SYNC PROMISE');
      _gasStorageSyncPromise = new Promise((res, rej) => {
        try {
          // Get multiple script properties in one call, then log them all.
          const data = globalThis.getUserProperties();
          console.log('PROPERTIES:');
          for (const key in data) {
            if (key.startsWith(GASSTORAGE_KEY_PREFIX)) {
              console.log(`Key: ${key}, Value: ${data[key]}`);
              const value = data[key];
              const memoryKey = key.replace(GASSTORAGE_KEY_PREFIX, '');
              dataMemory[memoryKey] = value;
            }
          }
          res();
        } catch (err) {
          console.log('GasStorage Sync Failed with error %s', err.message);
          rej(err);
        }
      });
    }
    return _gasStorageSyncPromise;
    // if (!GasStorage.syncPromise) {
    //   GasStorage.syncPromise = new Promise((res, rej) => {
    //     try {
    //       // Get multiple script properties in one call, then log them all.
    //       const scriptProperties = PropertiesService.getScriptProperties();
    //       const data = PropertiesService.getUserProperties().getProperties();
    //       for (const key in data) {
    //         if (key.startsWith(GASSTORAGE_KEY_PREFIX)) {
    //           const value = data[key];
    //           const memoryKey = key.replace(GASSTORAGE_KEY_PREFIX, '');
    //           dataMemory[memoryKey] = value;
    //         }
    //       }
    //       res();
    //     } catch (err) {
    //       console.log('GasStorage Sync Failed with error %s', err.message);
    //       rej(err);
    //     }
    //   });
    // }
    // return GasStorage.syncPromise;
  }
}
