import { createContext, useState } from 'react';

export const ConsoleContext = createContext(null);

const ConsoleProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  return (
    <ConsoleContext.Provider value={{ messages, setMessages }}>
      {children}
    </ConsoleContext.Provider>
  );
}

export default ConsoleProvider;