"use strict";
/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
exports.__esModule = true;
exports.ModelSortDirection = exports.VisibilityType = exports.ModelAttributeTypes = exports.ParameterType = void 0;
var ParameterType;
(function (ParameterType) {
    ParameterType["STRING"] = "STRING";
    ParameterType["TEXT"] = "TEXT";
    ParameterType["INTEGER"] = "INTEGER";
    ParameterType["NUMBER"] = "NUMBER";
    ParameterType["BOOLEAN"] = "BOOLEAN";
    ParameterType["DATETIME"] = "DATETIME";
    ParameterType["IMAGE"] = "IMAGE";
    ParameterType["VIDEO"] = "VIDEO";
    ParameterType["PASSWORD"] = "PASSWORD";
    ParameterType["DICTIONARY"] = "DICTIONARY";
    ParameterType["TABLE"] = "TABLE";
})(ParameterType = exports.ParameterType || (exports.ParameterType = {}));
var ModelAttributeTypes;
(function (ModelAttributeTypes) {
    ModelAttributeTypes["binary"] = "binary";
    ModelAttributeTypes["binarySet"] = "binarySet";
    ModelAttributeTypes["bool"] = "bool";
    ModelAttributeTypes["list"] = "list";
    ModelAttributeTypes["map"] = "map";
    ModelAttributeTypes["number"] = "number";
    ModelAttributeTypes["numberSet"] = "numberSet";
    ModelAttributeTypes["string"] = "string";
    ModelAttributeTypes["stringSet"] = "stringSet";
    ModelAttributeTypes["_null"] = "_null";
})(ModelAttributeTypes = exports.ModelAttributeTypes || (exports.ModelAttributeTypes = {}));
var VisibilityType;
(function (VisibilityType) {
    VisibilityType["PUBLIC"] = "PUBLIC";
    VisibilityType["PROTECTED"] = "PROTECTED";
    VisibilityType["PRIVATE"] = "PRIVATE";
})(VisibilityType = exports.VisibilityType || (exports.VisibilityType = {}));
var ModelSortDirection;
(function (ModelSortDirection) {
    ModelSortDirection["ASC"] = "ASC";
    ModelSortDirection["DESC"] = "DESC";
})(ModelSortDirection = exports.ModelSortDirection || (exports.ModelSortDirection = {}));
