import { useContext } from 'react';
import { ConsoleContext } from '../../providers/ConsoleProvider';

const Console = () => {
  const { messages } = useContext(ConsoleContext);
  return (
    <div className='absolute flex flex-col h-48 bottom-0 w-full bg-gray-700 border border-green-300 rounded-lg font-mono text-xs p-2.5 space-y-2 overflow-y-auto'>
      {messages.map(message => {
        const style = message.sender === 'user' ? 'text-green-300' : 'self-end items-end text-blue-100';
        return (
          <div className={`flex flex-col break-all ${style}`}>
            <div className='underline'>{message.name}</div>
            <div>{JSON.stringify(message.data)}</div>
          </div>
        );
      })}
    </div>
  );
}

export default Console;