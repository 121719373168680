import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createMemoryRouter,
  RouterProvider,
} from 'react-router-dom';
import './index.css';
import App from './App';
import HomePage from './components/home/HomePage';
import LoginPage from './components/login/LoginPage';
import FlowDetailsPage, {
  loader as flowDetailsPageLoader,
} from './components/flowDetails/FlowDetailsPage';
import UserProvider from './providers/UserProvider';
import ConsoleProvider from './providers/ConsoleProvider';
import { isGASEnvironment } from './utils/gasUtils';

const root = ReactDOM.createRoot(document.getElementById('root'));

var globalThis = globalThis || {};

if (isGASEnvironment()) {
  console.log('AT GAS WEB SETTING PROPERTIES SERVICE!');
  // console.log(window.google.script.run.getPropertiesService);
  // console.log(window.google.script.run.getPropertiesService());
  // console.log(
  //   window.google.script.run.getPropertiesService().getUserProperties
  // );

  //console.log(window.google.script.run.getPropertiesService());
  /*console.log(
    window.google.script.run.getPropertiesService().getUserProperties()
  );*/
  globalThis.setUserProperty = window.google.script.run.setUserProperty;
  globalThis.getUserProperty = window.google.script.run.getUserProperty;
  globalThis.deleteUserProperty = window.google.script.run.deleteUserProperty;
  globalThis.getUserProperties = window.google.script.run.getUserProperties;
  globalThis.setProperty = window.google.script.run.setUserProperty;
  globalThis.getProperties = window.google.script.run.getUserProperties;
  globalThis.deleteProperty = window.google.script.run.deleteUserProperty;

  //globalThis.PropertiesService = window.google.script.run.getPropertiesService;
}

const routerFunc = isGASEnvironment()
  ? createMemoryRouter
  : createBrowserRouter;
const router = routerFunc([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '',
        element: <HomePage />,
      },
      {
        path: 'signup',
        element: <LoginPage isLogin={false} />,
      },
      {
        path: 'login',
        element: <LoginPage isLogin={true} />,
      },
      {
        path: 'flows/:flowId',
        element: <FlowDetailsPage />,
        loader: flowDetailsPageLoader,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <UserProvider>
      <ConsoleProvider>
        <RouterProvider router={router} />
      </ConsoleProvider>
    </UserProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
