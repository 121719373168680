/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const echo = /* GraphQL */ `
  query Echo($msg: String) {
    echo(msg: $msg)
  }
`;
export const getFlowStep = /* GraphQL */ `
  query GetFlowStep($id: ID!) {
    getFlowStep(id: $id) {
      id
      referenceID
      app
      action
      name
      description
      inputSpec {
        key
        label
        description
        type
        isArray
        isRequired
        defaultValue
        dropdownOptions
        metadata
      }
      outputSpec {
        key
        label
        description
        type
        isArray
        isRequired
        defaultValue
        dropdownOptions
        metadata
      }
      params
      metadata
      flowID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFlowSteps = /* GraphQL */ `
  query ListFlowSteps(
    $filter: ModelFlowStepFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlowSteps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        referenceID
        app
        action
        name
        description
        inputSpec {
          key
          label
          description
          type
          isArray
          isRequired
          defaultValue
          dropdownOptions
          metadata
        }
        outputSpec {
          key
          label
          description
          type
          isArray
          isRequired
          defaultValue
          dropdownOptions
          metadata
        }
        params
        metadata
        flowID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFlowSteps = /* GraphQL */ `
  query SyncFlowSteps(
    $filter: ModelFlowStepFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFlowSteps(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        referenceID
        app
        action
        name
        description
        inputSpec {
          key
          label
          description
          type
          isArray
          isRequired
          defaultValue
          dropdownOptions
          metadata
        }
        outputSpec {
          key
          label
          description
          type
          isArray
          isRequired
          defaultValue
          dropdownOptions
          metadata
        }
        params
        metadata
        flowID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const flowStepsByFlowID = /* GraphQL */ `
  query FlowStepsByFlowID(
    $flowID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFlowStepFilterInput
    $limit: Int
    $nextToken: String
  ) {
    flowStepsByFlowID(
      flowID: $flowID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        referenceID
        app
        action
        name
        description
        inputSpec {
          key
          label
          description
          type
          isArray
          isRequired
          defaultValue
          dropdownOptions
          metadata
        }
        outputSpec {
          key
          label
          description
          type
          isArray
          isRequired
          defaultValue
          dropdownOptions
          metadata
        }
        params
        metadata
        flowID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPrediction = /* GraphQL */ `
  query GetPrediction($id: ID!) {
    getPrediction(id: $id) {
      id
      flowID
      input
      response
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPredictions = /* GraphQL */ `
  query ListPredictions(
    $filter: ModelPredictionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPredictions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        flowID
        input
        response
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPredictions = /* GraphQL */ `
  query SyncPredictions(
    $filter: ModelPredictionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPredictions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        flowID
        input
        response
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const predictionsByFlowID = /* GraphQL */ `
  query PredictionsByFlowID(
    $flowID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPredictionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    predictionsByFlowID(
      flowID: $flowID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        flowID
        input
        response
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFlow = /* GraphQL */ `
  query GetFlow($id: ID!) {
    getFlow(id: $id) {
      id
      Predictions {
        items {
          id
          flowID
          input
          response
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      owner
      name
      description
      alias
      inputSpec {
        key
        label
        description
        type
        isArray
        isRequired
        defaultValue
        dropdownOptions
        metadata
      }
      outputSpec {
        key
        label
        description
        type
        isArray
        isRequired
        defaultValue
        dropdownOptions
        metadata
      }
      entryStepReferenceID
      exitStepReferenceID
      stepConnections
      visibility
      StepNodes {
        items {
          id
          referenceID
          app
          action
          name
          description
          inputSpec {
            key
            label
            description
            type
            isArray
            isRequired
            defaultValue
            dropdownOptions
            metadata
          }
          outputSpec {
            key
            label
            description
            type
            isArray
            isRequired
            defaultValue
            dropdownOptions
            metadata
          }
          params
          metadata
          flowID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFlows = /* GraphQL */ `
  query ListFlows(
    $filter: ModelFlowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Predictions {
          items {
            id
            flowID
            input
            response
            owner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        owner
        name
        description
        alias
        inputSpec {
          key
          label
          description
          type
          isArray
          isRequired
          defaultValue
          dropdownOptions
          metadata
        }
        outputSpec {
          key
          label
          description
          type
          isArray
          isRequired
          defaultValue
          dropdownOptions
          metadata
        }
        entryStepReferenceID
        exitStepReferenceID
        stepConnections
        visibility
        StepNodes {
          items {
            id
            referenceID
            app
            action
            name
            description
            params
            metadata
            flowID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFlows = /* GraphQL */ `
  query SyncFlows(
    $filter: ModelFlowFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFlows(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Predictions {
          items {
            id
            flowID
            input
            response
            owner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        owner
        name
        description
        alias
        inputSpec {
          key
          label
          description
          type
          isArray
          isRequired
          defaultValue
          dropdownOptions
          metadata
        }
        outputSpec {
          key
          label
          description
          type
          isArray
          isRequired
          defaultValue
          dropdownOptions
          metadata
        }
        entryStepReferenceID
        exitStepReferenceID
        stepConnections
        visibility
        StepNodes {
          items {
            id
            referenceID
            app
            action
            name
            description
            params
            metadata
            flowID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const flowsByAlias = /* GraphQL */ `
  query FlowsByAlias(
    $alias: String!
    $sortDirection: ModelSortDirection
    $filter: ModelFlowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    flowsByAlias(
      alias: $alias
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Predictions {
          items {
            id
            flowID
            input
            response
            owner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        owner
        name
        description
        alias
        inputSpec {
          key
          label
          description
          type
          isArray
          isRequired
          defaultValue
          dropdownOptions
          metadata
        }
        outputSpec {
          key
          label
          description
          type
          isArray
          isRequired
          defaultValue
          dropdownOptions
          metadata
        }
        entryStepReferenceID
        exitStepReferenceID
        stepConnections
        visibility
        StepNodes {
          items {
            id
            referenceID
            app
            action
            name
            description
            params
            metadata
            flowID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
