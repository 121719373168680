/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sandboxMutation = /* GraphQL */ `
  mutation SandboxMutation($flow: String, $params: String) {
    sandboxMutation(flow: $flow, params: $params)
  }
`;
export const genPrediction = /* GraphQL */ `
  mutation GenPrediction($flow: String, $params: String) {
    genPrediction(flow: $flow, params: $params)
  }
`;
export const createFlowStep = /* GraphQL */ `
  mutation CreateFlowStep(
    $input: CreateFlowStepInput!
    $condition: ModelFlowStepConditionInput
  ) {
    createFlowStep(input: $input, condition: $condition) {
      id
      referenceID
      app
      action
      name
      description
      inputSpec {
        key
        label
        description
        type
        isArray
        isRequired
        defaultValue
        dropdownOptions
        metadata
      }
      outputSpec {
        key
        label
        description
        type
        isArray
        isRequired
        defaultValue
        dropdownOptions
        metadata
      }
      params
      metadata
      flowID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFlowStep = /* GraphQL */ `
  mutation UpdateFlowStep(
    $input: UpdateFlowStepInput!
    $condition: ModelFlowStepConditionInput
  ) {
    updateFlowStep(input: $input, condition: $condition) {
      id
      referenceID
      app
      action
      name
      description
      inputSpec {
        key
        label
        description
        type
        isArray
        isRequired
        defaultValue
        dropdownOptions
        metadata
      }
      outputSpec {
        key
        label
        description
        type
        isArray
        isRequired
        defaultValue
        dropdownOptions
        metadata
      }
      params
      metadata
      flowID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFlowStep = /* GraphQL */ `
  mutation DeleteFlowStep(
    $input: DeleteFlowStepInput!
    $condition: ModelFlowStepConditionInput
  ) {
    deleteFlowStep(input: $input, condition: $condition) {
      id
      referenceID
      app
      action
      name
      description
      inputSpec {
        key
        label
        description
        type
        isArray
        isRequired
        defaultValue
        dropdownOptions
        metadata
      }
      outputSpec {
        key
        label
        description
        type
        isArray
        isRequired
        defaultValue
        dropdownOptions
        metadata
      }
      params
      metadata
      flowID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPrediction = /* GraphQL */ `
  mutation CreatePrediction(
    $input: CreatePredictionInput!
    $condition: ModelPredictionConditionInput
  ) {
    createPrediction(input: $input, condition: $condition) {
      id
      flowID
      input
      response
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePrediction = /* GraphQL */ `
  mutation UpdatePrediction(
    $input: UpdatePredictionInput!
    $condition: ModelPredictionConditionInput
  ) {
    updatePrediction(input: $input, condition: $condition) {
      id
      flowID
      input
      response
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePrediction = /* GraphQL */ `
  mutation DeletePrediction(
    $input: DeletePredictionInput!
    $condition: ModelPredictionConditionInput
  ) {
    deletePrediction(input: $input, condition: $condition) {
      id
      flowID
      input
      response
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFlow = /* GraphQL */ `
  mutation CreateFlow(
    $input: CreateFlowInput!
    $condition: ModelFlowConditionInput
  ) {
    createFlow(input: $input, condition: $condition) {
      id
      Predictions {
        items {
          id
          flowID
          input
          response
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      owner
      name
      description
      alias
      inputSpec {
        key
        label
        description
        type
        isArray
        isRequired
        defaultValue
        dropdownOptions
        metadata
      }
      outputSpec {
        key
        label
        description
        type
        isArray
        isRequired
        defaultValue
        dropdownOptions
        metadata
      }
      entryStepReferenceID
      exitStepReferenceID
      stepConnections
      visibility
      StepNodes {
        items {
          id
          referenceID
          app
          action
          name
          description
          inputSpec {
            key
            label
            description
            type
            isArray
            isRequired
            defaultValue
            dropdownOptions
            metadata
          }
          outputSpec {
            key
            label
            description
            type
            isArray
            isRequired
            defaultValue
            dropdownOptions
            metadata
          }
          params
          metadata
          flowID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFlow = /* GraphQL */ `
  mutation UpdateFlow(
    $input: UpdateFlowInput!
    $condition: ModelFlowConditionInput
  ) {
    updateFlow(input: $input, condition: $condition) {
      id
      Predictions {
        items {
          id
          flowID
          input
          response
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      owner
      name
      description
      alias
      inputSpec {
        key
        label
        description
        type
        isArray
        isRequired
        defaultValue
        dropdownOptions
        metadata
      }
      outputSpec {
        key
        label
        description
        type
        isArray
        isRequired
        defaultValue
        dropdownOptions
        metadata
      }
      entryStepReferenceID
      exitStepReferenceID
      stepConnections
      visibility
      StepNodes {
        items {
          id
          referenceID
          app
          action
          name
          description
          inputSpec {
            key
            label
            description
            type
            isArray
            isRequired
            defaultValue
            dropdownOptions
            metadata
          }
          outputSpec {
            key
            label
            description
            type
            isArray
            isRequired
            defaultValue
            dropdownOptions
            metadata
          }
          params
          metadata
          flowID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFlow = /* GraphQL */ `
  mutation DeleteFlow(
    $input: DeleteFlowInput!
    $condition: ModelFlowConditionInput
  ) {
    deleteFlow(input: $input, condition: $condition) {
      id
      Predictions {
        items {
          id
          flowID
          input
          response
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      owner
      name
      description
      alias
      inputSpec {
        key
        label
        description
        type
        isArray
        isRequired
        defaultValue
        dropdownOptions
        metadata
      }
      outputSpec {
        key
        label
        description
        type
        isArray
        isRequired
        defaultValue
        dropdownOptions
        metadata
      }
      entryStepReferenceID
      exitStepReferenceID
      stepConnections
      visibility
      StepNodes {
        items {
          id
          referenceID
          app
          action
          name
          description
          inputSpec {
            key
            label
            description
            type
            isArray
            isRequired
            defaultValue
            dropdownOptions
            metadata
          }
          outputSpec {
            key
            label
            description
            type
            isArray
            isRequired
            defaultValue
            dropdownOptions
            metadata
          }
          params
          metadata
          flowID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
