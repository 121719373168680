import { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { listFlows } from '../../graphql/queries';
import { ListFlowsQuery, Flow } from '../../API';
import FlowForm from '../shared/FlowForm';
import Console from './Console';

const HomePage = () => {
  const [flowList, setFlowList] = useState<Flow[]>([]);
  const [selectedFlow, setSelectedFlow] = useState<Flow>(null);

  useEffect(() => {
    const getFlowList = async () => {
      try {
        const response = (await API.graphql({query: listFlows})) as { data: ListFlowsQuery };
        const flowList = response.data.listFlows.items;
        setFlowList(flowList);

        if (flowList[0]) {
          setSelectedFlow(flowList[0]);
        }
      } catch (error) {
        console.error(error);
      }
    }
    getFlowList();
  }, []);

  const handleSelectOnChange = (event) => {
    const selectedFlow = flowList.find(flow => flow.id === event.target.value);
    setSelectedFlow(selectedFlow);
  }

  return (
    <div className='flex flex-1 relative flex-col mx-6 my-4 text-sm min-h-0'>
      <div className='flex flex-1 flex-col space-y-4 overflow-auto pb-52'>
        <select 
          className='bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block p-2.5'
          onChange={handleSelectOnChange}
          value={selectedFlow && selectedFlow.id}
        >
          {flowList.map(flow => <option key={flow.id} value={flow.id}>{flow.name}</option>)}
        </select>
        {selectedFlow && selectedFlow.inputSpec && <FlowForm flow={selectedFlow} />}
      </div>
      <Console />
    </div>
  );
};

export default HomePage;
