import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { UserContext } from '../../providers/UserProvider';

type LoginData = {
  email: string;
  password: string;
}

const LoginPage = ({ isLogin }) => {
  const { setUser } = useContext(UserContext);
  const { register, handleSubmit } = useForm<LoginData>();
  const navigate = useNavigate();

  let title = 'Log In';
  let alternateOptionURL = '/signup';
  let alternateOptionText = 'New user? Create account';
  let forgotPasswordLink = (
    <Link to='#'>
      <span className='italic text-xs float-right text-blue-500 hover:text-blue-800 hover:cursor-pointer'>
        Forgot Password?
      </span>
    </Link>
  );

  if (!isLogin) {
    title = 'Sign Up';
    alternateOptionURL = '/login';
    alternateOptionText = 'Have an account? Sign in';
    forgotPasswordLink = null;
  }

  const onSubmit = async (data) => {
    let user = null;
    if (isLogin) {
      user = await Auth.signIn(data.email, data.password);
      setUser(user);
    } else {
      user = await Auth.signUp({
        username: data.email,
        password: data.password
      });
      setUser(user);
      console.log('USER', user);
    }

    if (user) {
      navigate('/');
    }
  }

  return (
    <div className='flex-1 w-full flex justify-center items-center bg-gray-100'>
      <div>
        <p className='text-center text-xl font-bold mb-4'>{title}</p>
        <div className='w-full max-w-sm'>
          <form className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4' onSubmit={handleSubmit(onSubmit)}>
            <div className='mb-4'>
              <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='email'>
                Email
              </label>
              <input className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                {...register('email', { required: true })}
                placeholder='Email' />
            </div>
            <div className='mb-6'>
              <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='password'>
                Password
              </label>
              <input className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline'
                {...register('password', { required: true })}
                type='password'
                placeholder='Password' />
            </div>
            <div className='flex items-center justify-between'>
              <input className='bg-blue-500 hover:bg-blue-700 hover:cursor-pointer text-white font-bold py-2 px-4 mr-6 rounded focus:outline-none focus:shadow-outline'
                type='submit'
                value='Sign In' />
              <div className='flex-1'>
                <Link to={alternateOptionURL}>
                  <span className='italic text-xs float-right text-blue-500 hover:text-blue-800 hover:cursor-pointer'>
                    {alternateOptionText}
                  </span>
                </Link>
                {forgotPasswordLink}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
