import awsconfig from '../aws-exports';
import { isGASEnvironment, GasStorage } from './gasUtils';

export const getAWSConfig = () => {
  //return awsconfig;
  //return { ...awsconfig, ssr: true };
  return isGASEnvironment()
    ? Object.assign(awsconfig, {
        Auth: {
          storage: GasStorage,
        },
      })
    : awsconfig;
};
